import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Container } from "@mui/system";
import ProfileTabs from "../components/molecules/ProfileTabs";
import NftCardDescription from "../components/molecules/nftCards/NftCardDescription";
import { isUserAuthenticated } from "../utils/utils";
import urlConstants from "../constants/urlConstants.js";
import { nftAxios } from "../services/apiService.js";
import ProfileBanner from "../components/organisms/ProfileBanner";
import { useSelector } from "react-redux";
import NftGridLayout from "../components/molecules/NftGridLayout";
import NftCardProfile from "../components/molecules/nftCards/NftCardProfile";
import PdfReader from "../components/organisms/PdfReader";
import { Link } from "gatsby";

import { Helmet } from "react-helmet";
import Filter from "../components/molecules/Filter";
import Button from "../components/atoms/Button";

const MYCOLLS = "MY COLLECTIONS";
const MYNFTS = "MY NFTs";

const tabs = [MYCOLLS, MYNFTS];
const sortOptions = ["A-Z", "Z-A"];
const Profile = () => {
  const [activeTab, setActiveTab] = useState();

  useEffect(() => {
    setActiveTab(tabs[0]);
  }, []);

  const [dataList, setDataList] = useState([]);
  const [noData, setNoData] = useState(false);
  const [collectionList, setCollectionList] = useState([]);
  const [tokenList, setTokenList] = useState([]);
  const [finalCollectionList, setFinalCollectionList] = useState([]);
  const [finalTokenList, setFinalTokenList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showComic, setShowComic] = useState(false);
  const [assetUrl, setAssetUrl] = useState();
  const [sortFilter, setSortFilter] = useState();
  const [showAllNftBtn, setShowAllNftBtn] = useState(false);

  const userLoginState = useSelector((state) => state.isLogged);
  useEffect(() => {
    if (isUserAuthenticated()) {
      setCollectionList([]);
      setTokenList([]);
      setNoData(false);
      setShowLoading(true);
      nftAxios
        .get(`${urlConstants.getTokensOwned}`)
        .then((response) => {
          if (response.data.length) {
            setDataList(response.data);
          } else {
            setNoData(true);
          }

          setShowLoading(false);
        })
        .catch((err) => {
          console.log("error occured", err);
          setShowLoading(false);
        });
    }
  }, [userLoginState]);

  useEffect(() => {
    if (dataList.length) {
      const token = [];
      const collection = [];
      dataList.forEach((item) => {
        const artefyCol = item?.aspen_collection?.artefy_collection;
        const artefyToken = item?.aspen_token;
        if (!collection.find((item) => item.id === artefyCol.id)) {
          collection.push({
            id: artefyCol?.id,
            img: artefyCol?.featured_image?.url,
            name: artefyCol?.name,
            description: artefyCol?.description,
            link: `/marketplace/nfts/collections/${artefyCol?.slug}`,
          });
        }

        if (!token.find((item) => item.id === artefyToken.id)) {
          token.push({
            id: artefyToken?.id,
            img: artefyToken?.featured_image?.url,
            name: artefyToken?.name,
            link: `/marketplace/nfts/${artefyCol?.slug}/${artefyToken?.slug}`,
            artists: artefyToken?.artists,
            ownedCount: artefyToken?.ownedCount,
            totalCount: artefyToken?.totalCount,
            collectionId: artefyCol?.id,
            assetUrl: artefyToken?.aspen_token_assets[0]?.aspen_url || "",
            tokenTypeComic:
              artefyToken?.aspen_token_type?.id ===
              +process.env.GATSBY_TOKEN_TYPE_COMIC,
          });
        }
      });
      setCollectionList(collection);
      setTokenList(token);
    }
  }, [dataList]);

  const sortList = (filter, list) => {
    const updatedList = [...list];
    if (filter === "A-Z") {
      updatedList.sort((a, b) => {
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      });
    }
    if (filter === "Z-A") {
      updatedList.sort((a, b) => {
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x < y) return 1;
        if (x > y) return -1;
        return 0;
      });
    }
    return updatedList;
  };

  useEffect(() => {
    if (sortFilter) {
      if (collectionList.length || tokenList.length) {
        const sortedCollectionList = sortList(sortFilter, collectionList);
        const sortedTokenList = sortList(sortFilter, tokenList);
        setFinalCollectionList(sortedCollectionList);
        setFinalTokenList(sortedTokenList);
      }
    } else {
      setFinalCollectionList(collectionList);
      setFinalTokenList(tokenList);
    }
  }, [sortFilter, collectionList, tokenList]);

  const handleReadComic = (asset) => {
    if (asset) {
      setAssetUrl(asset);
      setShowComic(true);
    }
  };

  const handleCollectionClicked = (collectionId) => {
    const a = tokenList.filter((token) => token.collectionId === collectionId);
    setFinalTokenList(a);
    setActiveTab(tabs[1]);
    setShowAllNftBtn(true);
  };

  const showAllNfts = () => {
    setShowAllNftBtn(false);
    let updatedTokenList = [...tokenList];
    if (sortFilter) {
      updatedTokenList = sortList(tokenList, tokenList);
    }
    setFinalTokenList(updatedTokenList);
  };

  return (
    <Layout>
      <Helmet>
        <title>{`${process.env.GATSBY_SITE_NAME} | My Profile`}</title>
      </Helmet>
      <Container maxWidth="lg" className="my-16">
        <div className="mb-12">
          <ProfileBanner />
        </div>
        <ProfileTabs
          options={tabs}
          activeTab={activeTab}
          onTabClick={setActiveTab}
        />
        <div className="mb-12 sm:mb-8 md:mb-0">
          <Filter
            selectedOption={sortFilter}
            options={sortOptions}
            onSelectOption={setSortFilter}
          />
        </div>
        {noData && (
          <h2 className="font-bold text-2xl text-center py-20">
            You have no NFTs. Start your collection at the{" "}
            <Link to="/marketplace" className="text-primary">
              marketplace
            </Link>
            .
          </h2>
        )}
        {showLoading ? (
          <div className="mx-auto w-full flex jusitfy-center items-center gap-y-8 flex-col py-20">
            <div className="loader"></div>
            <span>getting your collections</span>
          </div>
        ) : (
          <>
            {activeTab === MYCOLLS && finalCollectionList.length > 0 && (
              <>
                <NftGridLayout contentLength={finalCollectionList.length}>
                  {finalCollectionList.map((item, index) => {
                    return (
                      <NftCardDescription
                        key={item.id}
                        nftCardImg={item?.img}
                        name={item?.name}
                        description={item?.description}
                        noLink={true}
                        specialFunction={handleCollectionClicked.bind(
                          this,
                          item.id
                        )}
                      />
                    );
                  })}
                </NftGridLayout>
              </>
            )}
            {activeTab === MYNFTS && finalTokenList.length > 0 && (
              <>
                <NftGridLayout contentLength={finalTokenList.length}>
                  {finalTokenList.map((item) => (
                    <NftCardProfile
                      key={item.id}
                      img={item.img}
                      name={item.name}
                      link={item.link}
                      artists={item.artists}
                      owned={item.ownedCount}
                      total={item.totalCount}
                      tokenTypeComic={item.tokenTypeComic}
                      readComicHandler={handleReadComic.bind(
                        this,
                        item?.assetUrl
                      )}
                    />
                  ))}
                </NftGridLayout>
                {showAllNftBtn && (
                  <Button className="mx-auto" onClick={showAllNfts}>
                    Show All NFTs
                  </Button>
                )}
              </>
            )}
          </>
        )}
        {showComic && (
          <PdfReader
            hideModal={setShowComic.bind(this, false)}
            asset={assetUrl}
          />
        )}
      </Container>
    </Layout>
  );
};

export default Profile;
