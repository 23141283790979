import React, { useContext } from "react";
import { useSelector } from "react-redux";
// import Button from "../atoms/Button";
import EditIcon from "@mui/icons-material/Edit";
import { navigate } from "gatsby";
import AppContext from "../../context/AppContext";

const ProfileBanner = () => {
  const userLoginState = useSelector((state) => state.isLogged);
  const { updateEditProfileState } = useContext(AppContext);
  const editProfile = () => {
    updateEditProfileState(true);
    navigate("/register");
  };
  return (
    <>
      <div className="flex gap-x-20 pt-24 flex-col items-center justify-center sm:flex-row ">
        <div>
          <div
            style={{
              backgroundImage: `url(${userLoginState?.avatar?.url})`,
            }}
            className="bg-center bg-cover w-40 h-40 rounded-full relative"
          >
            <button
              onClick={editProfile}
              className="absolute right-2 bottom-2 w-8 h-8 bg-gray rounded-full flex justify-center items-center"
            >
              <EditIcon className="text-black text-sm" />
            </button>
          </div>
        </div>
        <div className="flex-grow flex flex-col gap-y-10">
          <div className="flex flex-col gap-y-5">
            <div className="flex items-baseline gap-x-2">
              <h1 className="text-3xl">
                {userLoginState?.user?.artefyUserName}
              </h1>
              <button onClick={editProfile} className="text-primary text-sm">
                <EditIcon className="text-primary text-sm" />
                Edit
              </button>
            </div>
            <span className="text-sm text-darkGray">
              {userLoginState?.userAccountId?.replace(
                userLoginState?.userAccountId?.substr(7, 31),
                "..."
              )}
            </span>

            <div className="flex flex-col gap-y-4 text-grayText">
              <h2>Notification Settings</h2>
              <div>
                <span className="text-darkGray">Email: </span>
                <span>{userLoginState?.user?.email}</span>
                <button
                  onClick={editProfile}
                  className="text-primary text-sm ml-2"
                >
                  <EditIcon className="text-primary text-sm" />
                  Edit
                </button>
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="flex gap-x-2">
                  <input
                    className="p-2 bg-black"
                    type="checkbox"
                    name="sendUpdates"
                    readOnly
                    checked={
                      userLoginState.user?.notification
                        ? userLoginState.user.notification.latest_blog_articles
                        : true
                    }
                  />
                  <span>Send me an updates on latest blog articles</span>
                </div>
                <div className="flex gap-x-2">
                  <input
                    type="checkbox"
                    name="dropNotification"
                    readOnly
                    checked={
                      userLoginState.user.notification
                        ? userLoginState.user.notification.new_drops
                        : true
                    }
                  />
                  <span>Notify me of new drops</span>
                </div>
                <div className="flex gap-x-2">
                  <input
                    type="checkbox"
                    name="marketing"
                    readOnly
                    checked={
                      userLoginState.user.notification
                        ? userLoginState.user.notification
                            .general_marketing_emails
                        : true
                    }
                  />
                  <span>Subscribe to general marketing emails</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileBanner;
