import React from "react";

const ProfileTabs = ({ options, activeTab, onTabClick, className }) => {
  return (
    <div className="mb-12">
      <ul className={`flex ${className}`}>
        {options.map((option) => {
          return (
            <li
              key={option}
              onClick={onTabClick.bind(this, option)}
              role="button"
              onKeyDown={onTabClick.bind(this, option)}
              tabIndex={-4}
              className={`py-3 border-b border-primary flex-grow text-center cursor-pointer ${
                activeTab === option ? "bg-primary" : ""
              }`}
            >
              {option}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProfileTabs;
