import { Link } from "gatsby";
import React from "react";
// import lysIcon from "../../../assets/images/lysIcon.svg";

const NftCardProfile = ({
  img,
  name,
  link,
  artists,
  owned,
  total,
  tokenTypeComic,
  readComicHandler,
}) => {
  return (
    <>
      <div className="min-w-[290px] max-w-[420px]  mx-auto sm:mx-0 w-full">
        <Link to={link}>
          <div className="relative">
            <div
              className="w-full h-[400px] bg-center bg-cover"
              style={{ backgroundImage: `url(${img})` }}
            ></div>
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-70 p-4">
              <h3 className="text-center text-2xl font-bold mb-2 uppercase">
                {name}
              </h3>
              <p className="text-center">
                By:{" "}
                <span className="text-red">
                  {artists.length ? (
                    <>
                      {artists.map((item, index) => {
                        return (
                          <span key={item.id}>
                            <span>{item?.first_name}</span>{" "}
                            <span>{item?.surname}</span>
                            {index + 1 < artists?.length && <span>,</span>}
                          </span>
                        );
                      })}
                    </>
                  ) : (
                    "unknown"
                  )}
                </span>
              </p>
            </div>
          </div>
        </Link>
        <div className="flex justify-between p-4 bg-mutedGray items-center">
          <div className="flex flex-col">
            <span className="text-xs">owned: {owned}</span>
            <span className="text-xs">total editions: {total}</span>
          </div>
          {tokenTypeComic && (
            <button onClick={readComicHandler} className="bg-primary py-2 px-4">
              Read Comic
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default NftCardProfile;
