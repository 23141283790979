import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "gatsby";

const NftCardDescription = ({
  nftCardImg,
  name,
  description,
  link,
  artists,
  noLink = false,
  specialFunction = () => null,
}) => {
  return (
    <>
      <div className="min-w-[290px] max-w-[420px] mx-auto sm:mx-0 w-full">
        <div className="relative">
          <div
            className="w-full h-[550px] bg-center bg-cover"
            style={{ backgroundImage: `url(${nftCardImg})` }}
          ></div>
          <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-70 p-4 flex flex-col gap-y-2 justify-center items-center">
            <h3 className="text-center text-2xl font-bold uppercase">{name}</h3>
            {/* {artists && (
              <div className="text-center font-medium">
                <span>By: </span>
                <span className="text-red font-bold">{artists}</span>
              </div>
            )} */}
            <span className="text-sm text-center mb-2">{description}</span>
            <div className="text-primary text-lg font-bold cursor-pointer">
              {!noLink && (
                <Link to={link}>
                  View <ArrowForwardIcon />
                </Link>
              )}
              {noLink && (
                <button onClick={specialFunction}>
                  View <ArrowForwardIcon />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NftCardDescription;
